var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.currentVisible
    ? _c("div", { staticClass: "dialog_box_mask" }, [
        _c(
          "div",
          {
            class: _vm.type === "pdf" ? "PDF_dialog_box" : "slot_dialog_box",
            style: { width: _vm.size, height: _vm.size },
          },
          [
            _vm.type === "pdf"
              ? _c(
                  "div",
                  { staticClass: "PDF_dialog_close", on: { click: _vm.close } },
                  [_vm._v("\n            关闭窗口\n        ")]
                )
              : _c("div", { staticClass: "slot_dialog_header" }, [
                  _c("span", { staticClass: "slot_dialog_title" }, [
                    _vm._v(_vm._s(_vm.title)),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "el-dialog__headerbtn",
                      attrs: { type: "button" },
                      on: { click: _vm.close },
                    },
                    [
                      _c("i", {
                        staticClass: "el-dialog__close el-icon el-icon-close",
                      }),
                    ]
                  ),
                ]),
            _vm._v(" "),
            _c("iframe", {
              attrs: {
                id: "framework",
                title: "framework",
                width: "100%",
                height: "100%",
                frameborder: "0",
                scrolling: "no",
                marginheight: "0",
                marginwidth: "0",
                src: _vm.pdfUrl,
              },
            }),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }