export default {
    baseUrl: '//pre-api.bqex.com/code',
    // wedataOrigin: 'http://192.168.94.22:9000',
    // 服务集合
    // baseOss: {
    //     '/base': 'http://hxbase.heisea.cn/'
    // },
    // 上传
    filePath: '//pre-api.bqex.com/code/base/fe/info', // 文件信息
    uploadPath: '//pre-api.bqex.com/code/base/fe/upload', // 上传
    batchFilePath: '//pre-api.bqex.com/code/base/fe/batchInfo', // 批量获取文件信息
    downloadPath: '//pre-api.bqex.com/code/base/fe/download'
};
