var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card-box", on: { click: _vm.handleClick } },
    [
      _vm.title
        ? _c("div", { staticClass: "card-title" }, [
            _c("div", { staticClass: "title-box" }, [
              !_vm.link
                ? _c("span", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.title)),
                  ])
                : _c(
                    "a",
                    {
                      staticClass: "link",
                      attrs: {
                        href: _vm.link,
                        title: _vm.link,
                        target: "_blank",
                      },
                    },
                    [
                      _vm._v(_vm._s(_vm.title)),
                      _c("i", { staticClass: "link-icon" }),
                    ]
                  ),
              _vm._v(" "),
              _vm.ps
                ? _c("span", { staticClass: "ps" }, [_vm._v(_vm._s(_vm.ps))])
                : _vm._e(),
              _vm._v(" "),
              _vm.tips
                ? _c("span", { staticClass: "tips" }, [
                    _vm._v(_vm._s(_vm.tips)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("span", { staticClass: "tips" }, [_vm._t("tips")], 2),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [_vm._t("default")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }