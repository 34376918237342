var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "warp" }, [
    _c("header", [
      _vm.left
        ? _c("div", { staticClass: "left" }, [
            _c("a", { attrs: { href: _vm.left.href, target: "other" } }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.left.title) +
                  "\n                "
              ),
              _vm.left.href ? _c("i", { staticClass: "link-icon" }) : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "middle" }, [
        _c("div", { staticClass: "lside" }),
        _vm._v(" "),
        _c("div", { staticClass: "content cursor" }, [
          _vm._v(
            "\n                " +
              _vm._s(_vm.currentTitle) +
              "\n                "
          ),
          _vm.options.length
            ? _c("i", { staticClass: "content-icon" })
            : _vm._e(),
          _vm._v(" "),
          _vm.options.length
            ? _c(
                "ul",
                { staticClass: "content-list" },
                _vm._l(_vm.options, function (item) {
                  return _c(
                    "li",
                    {
                      key: item.index,
                      on: { click: () => _vm.setValue(item) },
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(item.item_title) +
                          "\n                    "
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "lside rside" }),
      ]),
      _vm._v(" "),
      _vm.right
        ? _c("div", { staticClass: "right" }, [
            _vm.backUrl
              ? _c(
                  "a",
                  {
                    staticClass: "cursor",
                    on: {
                      click: function ($event) {
                        return _vm.$router.push(_vm.backUrl)
                      },
                    },
                  },
                  [_vm._v("返回")]
                )
              : _c(
                  "a",
                  {
                    staticClass: "cursor",
                    on: {
                      click: function ($event) {
                        return _vm.$router.go(-1)
                      },
                    },
                  },
                  [_vm._v("返回")]
                ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }