var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.className, style: _vm.styles, on: { click: _vm.handleClick } },
    [
      _vm.index
        ? _c("span", {
            staticClass: "vw-li_flag",
            style: _vm.flagStyle,
            attrs: { "data-flag": _vm.index },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { class: `vw-li_con ${_vm.contentClass}`, style: _vm.contentStyle },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "vw-li_right" }, [_vm._t("right")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }