/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2020-08-10 10:48:35
 * @Last Modified by: zhujl3
 * @Last Modified time: 2024-12-06 17:23:34
 * @Description: 项目管理
 */

import View from '@/components/view';

const routes = [
    {
        path: 'project',
        component: View,
        meta: {
            title: '项目管理'
        },
        children: [
            {
                path: '',
                component: () => import(/* webpackChunkName: "project" */ '@/pages/hxcm/project'),
                meta: {
                    title: '项目管理'
                }
            }

        ]
    },
    {
        path: 'project-inner',
        component: View,
        meta: {
            title: '项目内'
        },
        children: [
            {
                path: 'negotiations-management',
                component: View,
                meta: {
                    title: '谈判管理'
                },
                children: [
                    {
                        path: 'negotiations-price',
                        component: () => import(/* webpackChunkName: "expert-rate" */ '@/pages/hxcm/negotiations-management/negotiations-price'),
                        meta: {
                            title: '谈判议价'
                        }
                    }
                ]
            },
            {
                path: 'review-prepare',
                component: View,
                meta: {
                    title: '评审准备'
                },
                children: [
                    {
                        path: 'config-set',
                        component: () => import(/* webpackChunkName: "config-set" */ '@/pages/hxcm/review-prepare/config-set'),
                        meta: {
                            title: '项目参数设置'
                        }
                    },
                    {
                        path: 'score-detail',
                        component: () => import(/* webpackChunkName: "score-detail" */ '@/pages/hxcm/review-prepare/score-detail'),
                        meta: {
                            title: '综合评审指标配置'
                        }
                    },
                    {
                        path: 'score-detail/info',
                        component: () => import(/* webpackChunkName: "score-detail-info" */ '@/pages/hxcm/review-prepare/score-detail-info'),
                        meta: {
                            title: '主客评定项信息',
                            display: 'none'
                        }
                    },
                    {
                        path: 'expert-group-manage',
                        component: () => import(/* webpackChunkName: "expert-group-manage" */ '@/pages/hxcm/review-prepare/expert-group-manage'),
                        meta: {
                            title: '专家组管理'
                        }
                    },
                    {
                        path: 'expert-manage',
                        component: () => import(/* webpackChunkName: "expert-manage" */ '@/pages/hxcm/review-prepare/expert-manage'),
                        meta: {
                            title: '初审专家管理'
                        }
                    },
                    {
                        path: 'class-manage',
                        component: () => import(/* webpackChunkName: "class-manage" */ '@/pages/hxcm/review-prepare/class-manage'),
                        meta: {
                            title: '评审分类管理'
                        }
                    },
                    {
                        path: 'catalogue-manage',
                        component: () => import(/* webpackChunkName: "catalogue-manage" */ '@/pages/hxcm/review-prepare/catalogue-manage'),
                        meta: {
                            title: '评审目录管理'
                        }
                    },
                    {
                        path: 'product-group',
                        component: () => import(/* webpackChunkName: "product-group" */ '@/pages/hxcm/review-prepare/product-group'),
                        meta: {
                            title: '评审产品分组'
                        }
                    },
                    {
                        path: 'audit-task-detailed',
                        component: () => import(/* webpackChunkName: "audit-task-detailed" */ '@/pages/hxcm/review-prepare/audit-task-detailed'),
                        meta: {
                            title: '初审任务明细'
                        }
                    }
                    // product-group

                ]
            },
            {
                path: 'abnormal-score',
                component: View,
                meta: {
                    title: '异常评分管理'
                },
                children: [
                    {
                        path: 'result-execute',
                        component: () => import(/* webpackChunkName: "abnormal-score-result-execute" */ '@/pages/hxcm/review-manage/abnormal-score/result-execute'),
                        meta: {
                            title: '异常评分结果执行',
                            keepAlive: true
                        }
                    },
                    {
                        path: 'result-correct',
                        component: () => import(/* webpackChunkName: "abnormal-score-result-correct" */ '@/pages/hxcm/review-manage/abnormal-score/result-correct'),
                        meta: {
                            title: '异常评分结果修正',
                            keepAlive: true
                        }
                    }
                ]
            },
            {
                path: 'review-manage',
                component: View,
                meta: {
                    title: '评审管理'
                },
                children: [
                    {
                        path: 'company-item',
                        component: () => import(/* webpackChunkName: "company-item" */ '@/pages/hxcm/review-manage/company-item'),
                        meta: {
                            title: '企业指标初审'
                        }
                    },
                    {
                        path: 'company-item/detail',
                        component: () => import(/* webpackChunkName: "company-item-detail-edit" */ '@/pages/hxcm/review-manage/company-item-detail-edit'),
                        // component: () => import(/* webpackChunkName: "company-item-detail" */ '@/pages/hxcm/review-manage/company-item-detail'),
                        meta: {
                            title: '详情',
                            display: 'none'
                        }
                    },
                    {
                        path: 'company-item/detail/edit',
                        component: () => import(/* webpackChunkName: "company-item-detail-edit" */ '@/pages/hxcm/review-manage/company-item-detail-edit'),
                        meta: {
                            title: '专家评审',
                            display: 'none'
                        }
                    },
                    {
                        path: 'company-item/print',
                        component: () => import(/* webpackChunkName: "company-item-print" */ '@/pages/hxcm/review-manage/company-item-print'),
                        meta: {
                            title: '打印',
                            display: 'none'
                        }
                    },
                    {
                        path: 'product-review',
                        component: () => import(/* webpackChunkName: "product-review" */ '@/pages/hxcm/review-manage/product-review'),
                        meta: {
                            title: '品种指标初审'
                        }
                    },
                    {
                        path: 'product-review/detail',
                        component: () => import(/* webpackChunkName: "product-review-detail-edit" */ '@/pages/hxcm/review-manage/product-review-detail-edit'),
                        // component: () => import(/* webpackChunkName: "product-review-detail" */ '@/pages/hxcm/review-manage/product-review-detail'),
                        meta: {
                            title: '详情',
                            display: 'none'
                        }
                    },
                    {
                        path: 'product-review/detail/edit',
                        component: () => import(/* webpackChunkName: "product-review-detail-edit" */ '@/pages/hxcm/review-manage/product-review-detail-edit'),
                        meta: {
                            title: '专家评审',
                            display: 'none'
                        }
                    },
                    {
                        path: 'product-review/print',
                        component: () => import(/* webpackChunkName: "product-review-print" */ '@/pages/hxcm/review-manage/product-review-print'),
                        meta: {
                            title: '打印',
                            display: 'none'
                        }
                    },


                    {
                        path: 'product-item',
                        component: () => import(/* webpackChunkName: "product-item" */ '@/pages/hxcm/review-manage/product-item'),
                        meta: {
                            title: '产品评定项申报管理'
                        }
                    },
                    {
                        path: 'product-item/detail',
                        component: () => import(/* webpackChunkName: "product-item-detail" */ '@/pages/hxcm/review-manage/product-item-detail'),
                        meta: {
                            title: '详情',
                            display: 'none'
                        }
                    },
                    {
                        path: 'score-count',
                        component: () => import(/* webpackChunkName: "score-count" */ '@/pages/hxcm/review-manage/score-count'),
                        meta: {
                            title: '评分结果计算'
                        }
                    },
                    {
                        path: 'result-manage',
                        component: () => import(/* webpackChunkName: "result-manage" */ '@/pages/hxcm/review-manage/result-manage'),
                        meta: {
                            title: '拟入围结果管理'
                        }
                    },
                    {
                        path: 'result-calculation',
                        component: () => import(/* webpackChunkName: "result-calculation" */ '@/pages/hxcm/review-manage/result-calculation'),
                        meta: {
                            title: '拟入围结果计算'
                        }
                    },
                    {
                        path: 'final-result-manage',
                        component: () => import(/* webpackChunkName: "final-result-manage" */ '@/pages/hxcm/review-manage/final-result-manage'),
                        meta: {
                            title: '入围结果管理'
                        }
                    },
                    {
                        path: 'equality-result-manage',
                        component: () => import(/* webpackChunkName: "equality-result-manage" */ '@/pages/hxcm/review-manage/equality-result-manage'),
                        meta: {
                            title: '相等顺位结果管理'
                        }
                    },
                    {
                        path: 'review-result-recheck',
                        component: () => import(/* webpackChunkName: "review-result-recheck" */ '@/pages/hxcm/review-manage/review-result-recheck'),
                        meta: {
                            title: '专家评审结果复查',
                            keepAlive: true
                        }
                    },
                    {
                        path: 'review-result-recheck/product-recheck',
                        component: () => import(/* webpackChunkName: "review-result-recheck-product" */ '@/pages/hxcm/review-manage/review-result-recheck/product-recheck'),
                        meta: {
                            title: '复查',
                            display: 'none'
                        }
                    },
                    {
                        path: 'review-result-recheck/company-recheck',
                        component: () => import(/* webpackChunkName: "review-result-recheck-company" */ '@/pages/hxcm/review-manage/review-result-recheck/company-recheck'),
                        meta: {
                            title: '复查',
                            display: 'none'
                        }
                    }
                    // score-count

                ]
            },
            {
                path: 'review-sum',
                component: View,
                meta: {
                    title: '评审汇总'
                },
                children: [
                    {
                        path: 'org-list',
                        component: () => import(/* webpackChunkName: "review-sum-orglist" */ '@/pages/hxcm/review-sum/org/index'),
                        meta: {
                            title: '企业指标初审结果汇总'
                        }
                    },
                    {
                        path: 'org-list/print',
                        component: () => import(/* webpackChunkName: "review-sum-orglist" */ '@/pages/hxcm/review-sum/org/results'),
                        meta: {
                            title: '打印评审结果'
                        }
                    },
                    {
                        path: 'prod-list',
                        component: () => import(/* webpackChunkName: "review-sum-prodlist" */ '@/pages/hxcm/review-sum/prod/index'),
                        meta: {
                            title: '品种指标初审结果汇总'
                        }
                    },
                    {
                        path: 'prod-list/print',
                        component: () => import(/* webpackChunkName: "review-sum-prodlist-print" */ '@/pages/hxcm/review-sum/prod/results'),
                        meta: {
                            title: '品种指标初审结果汇总/打印评审结果',
                            display: 'none'
                        }
                    }
                ]
            },
            {
                path: 'expert-evaluation',
                component: View,
                meta: {
                    title: '专家评审'
                },
                children: [
                    {
                        path: 'expert-rate',
                        component: () => import(/* webpackChunkName: "expert-rate" */ '@/pages/hxcm/expert-evaluation/expert-rate'),
                        meta: {
                            title: '专家评分'
                        }
                    },
                    {
                        path: 'expert-rate/detail',
                        component: () => import(/* webpackChunkName: "expert-rate-detail" */ '@/pages/hxcm/expert-evaluation/expert-rate-detail'),
                        meta: {
                            title: '目录企业打分',
                            display: 'none'
                        }
                    },
                    {
                        path: 'expert-rate/detail2',
                        component: () => import(/* webpackChunkName: "expert-rate-detail2" */ '@/pages/hxcm/expert-evaluation/expert-rate-detail2'),
                        meta: {
                            title: '目录产品打分',
                            display: 'none'
                        }
                    },
                    {
                        path: 'expert-rate/detail2/detail',
                        component: () => import(/* webpackChunkName: "expert-rate-detail2/detail" */ '@/pages/hxcm/expert-evaluation/expert-rate-detail2-detail'),
                        meta: {
                            title: '详情',
                            display: 'none'
                        }
                    }
                    // score-count
                ]
            },
            {
                path: 'inclusion-release-manage',
                component: View,
                meta: {
                    title: '入选发布管理'
                },
                children: [
                    {
                        path: 'inclusion-result-manage',
                        component: () => import(/* webpackChunkName: "inclusion-result-manage" */ '@/pages/hxcm/inclusion-release-manage/inclusion-result-manage'),
                        meta: {
                            title: '拟中选产品管理'
                        }
                    },
                    {
                        path: 'inclusion-result-calc',
                        component: () => import(/* webpackChunkName: "inclusion-result-calc" */ '@/pages/hxcm/inclusion-release-manage/inclusion-result-calc'),
                        meta: {
                            title: '拟中选结果计算'
                        }
                    },
                    {
                        path: 'inclusion-result-public',
                        component: () => import(/* webpackChunkName: "inclusion-result-public" */ '@/pages/hxcm/inclusion-release-manage/inclusion-result-public'),
                        meta: {
                            title: '拟中选结果公示'
                        }
                    },
                    {
                        path: 'index',
                        component: () => import(/* webpackChunkName: "inclusion-release-manage" */ '@/pages/hxcm/inclusion-release-manage'),
                        meta: {
                            title: '中选发布管理'
                        }
                    },
                    {
                        path: 'inclusion-product-query',
                        component: () => import(/* webpackChunkName: "inclusion-product-query" */ '@/pages/hxcm/inclusion-release-manage/inclusion-product-query'),
                        meta: {
                            title: '中选产品查询'
                        }
                    }

                ]
            },
            {
                path: 'complaint-manage',
                component: View,
                meta: {
                    title: '申投诉管理'
                },
                children: [
                    {
                        path: 'complaint-class',
                        component: () => import(/* webpackChunkName: "complaint-class" */ '@/pages/hxcm/complaint-manage/complaint-class'),
                        meta: {
                            title: '申投诉事项管理'
                        }
                    },
                    {
                        path: 'complaint-deal',
                        component: () => import(/* webpackChunkName: "complaint-deal" */ '@/pages/hxcm/complaint-manage/complaint-deal'),
                        meta: {
                            title: '申投诉管理',
                            keepAlive: true
                        }
                    },
                    {
                        path: 'complaint-deal/print',
                        component: () => import(/* webpackChunkName: "complaint-deal-print" */ '@/pages/hxcm/complaint-manage/complaint-deal-print'),
                        meta: {
                            title: '申投诉管理打印',
                            display: 'none'
                        }
                    },
                    {
                        path: 'complaint-deal/detail',
                        component: () => import(/* webpackChunkName: "inclusion-result-public" */ '@/pages/hxcm/complaint-manage/complaint-deal-detail'),
                        meta: {
                            title: '详情',
                            display: 'none'
                        }
                    },
                    {
                        path: 'complaint-result-deal',
                        component: () => import(/* webpackChunkName: "complaint-result-deal" */ '@/pages/hxcm/complaint-manage/complaint-result-deal'),
                        meta: {
                            title: '申投诉结果处理',
                            keepAlive: true
                        }
                    },
                    {
                        path: 'complaint-result-deal/detail',
                        component: () => import(/* webpackChunkName: "complaint-result-deal-detail" */ '@/pages/hxcm/complaint-manage/complaint-result-deal-detail'),
                        meta: {
                            title: '详情',
                            display: 'none'
                        }
                    },
                    {
                        path: 'complaint-result-execute',
                        component: () => import(/* webpackChunkName: "complaint-result-execute" */ '@/pages/hxcm/complaint-manage/complaint-result-execute'),
                        meta: {
                            title: '申投诉结果执行',
                            keepAlive: true
                        }
                    },
                    {
                        path: 'complaint-result-execute/detail',
                        component: () => import(/* webpackChunkName: "complaint-result-execute-detail" */ '@/pages/hxcm/complaint-manage/complaint-result-execute-detail'),
                        meta: {
                            title: '详情',
                            display: 'none'
                        }
                    }
                ]
            },
            {
                path: 'complaint-manage-score',
                component: View,
                meta: {
                    title: '综合得分公示申投诉管理'
                },
                children: [
                    {
                        path: 'complaint-deal',
                        component: () => import(/* webpackChunkName: "complaint-deal" */ '@/pages/hxcm/complaint-manage/score-publicity/complaint-deal'),
                        meta: {
                            title: '申投诉管理',
                            keepAlive: true
                        }
                    },
                    {
                        path: 'complaint-deal/print',
                        component: () => import(/* webpackChunkName: "complaint-deal-print" */ '@/pages/hxcm/complaint-manage/score-publicity/complaint-deal-print'),
                        meta: {
                            title: '申投诉管理打印',
                            display: 'none'
                        }
                    },
                    {
                        path: 'complaint-deal/detail',
                        component: () => import(/* webpackChunkName: "inclusion-result-public" */ '@/pages/hxcm/complaint-manage/score-publicity/complaint-deal-detail'),
                        meta: {
                            title: '详情',
                            display: 'none'
                        }
                    },
                    {
                        path: 'complaint-result-deal',
                        component: () => import(/* webpackChunkName: "complaint-result-deal" */ '@/pages/hxcm/complaint-manage/score-publicity/complaint-result-deal'),
                        meta: {
                            title: '申投诉结果处理',
                            keepAlive: true
                        }
                    },
                    {
                        path: 'complaint-result-deal/detail',
                        component: () => import(/* webpackChunkName: "complaint-result-deal-detail" */ '@/pages/hxcm/complaint-manage/score-publicity/complaint-result-deal-detail'),
                        meta: {
                            title: '详情',
                            display: 'none'
                        }
                    },
                    {
                        path: 'complaint-result-execute',
                        component: () => import(/* webpackChunkName: "complaint-result-execute" */ '@/pages/hxcm/complaint-manage/score-publicity/complaint-result-execute'),
                        meta: {
                            title: '申投诉结果执行',
                            keepAlive: true
                        }
                    },
                    {
                        path: 'complaint-result-execute/detail',
                        component: () => import(/* webpackChunkName: "complaint-result-execute-detail" */ '@/pages/hxcm/complaint-manage/score-publicity/complaint-result-execute-detail'),
                        meta: {
                            title: '详情',
                            display: 'none'
                        }
                    }
                ]
            },
            {
                path: 'reAudit-review',
                component: View,
                meta: {
                    title: '复审'
                },
                children: [
                    {
                        path: 'company-item',
                        component: () => import(/* webpackChunkName: "reAudit-company-item" */ '@/pages/hxcm/reAudit-review/company-item/company-item'),
                        meta: {
                            title: '企业指标复审'
                        }
                    },
                    {
                        path: 'company-item/detail',
                        component: () => import(/* webpackChunkName: "reAudit-company-item-detail-edit" */ '@/pages/hxcm/reAudit-review/company-item/company-item-detail-edit'),
                        // component: () => import(/* webpackChunkName: "reAudit-company-item-detail" */ '@/pages/hxcm/reAudit-review/company-item/company-item-detail'),
                        meta: {
                            title: '详情',
                            display: 'none'
                        }
                    },
                    {
                        path: 'company-item/detail/edit',
                        component: () => import(/* webpackChunkName: "reAudit-company-item-detail-edit" */ '@/pages/hxcm/reAudit-review/company-item/company-item-detail-edit'),
                        meta: {
                            title: '专家复审',
                            display: 'none'
                        }
                    },
                    {
                        path: 'product-review',
                        component: () => import(/* webpackChunkName: "reAudit-product-review" */ '@/pages/hxcm/reAudit-review/product-review/product-review'),
                        meta: {
                            title: '品种指标复审'
                        }
                    },
                    {
                        path: 'product-review/detail',
                        component: () => import(/* webpackChunkName: "reAudit-product-review-detail-edit" */ '@/pages/hxcm/reAudit-review/product-review/product-review-detail-edit'),
                        // component: () => import(/* webpackChunkName: "reAudit-product-review-detail" */ '@/pages/hxcm/reAudit-review/product-review/product-review-detail'),
                        meta: {
                            title: '详情',
                            display: 'none'
                        }
                    },
                    {
                        path: 'product-review/detail/edit',
                        component: () => import(/* webpackChunkName: "reAudit-product-review-detail-edit" */ '@/pages/hxcm/reAudit-review/product-review/product-review-detail-edit'),
                        meta: {
                            title: '专家复审',
                            display: 'none'
                        }
                    },
                    {
                        path: 'org-review-sum',
                        component: () => import(/* webpackChunkName: "reAudit-review-sum-orglist" */ '@/pages/hxcm/reAudit-review/review-sum/org/index'),
                        meta: {
                            title: '企业指标复审结果汇总'
                        }
                    },
                    {
                        path: 'prod-review-sum',
                        component: () => import(/* webpackChunkName: "reAudit-review-sum-orglist" */ '@/pages/hxcm/reAudit-review/review-sum/prod/index'),
                        meta: {
                            title: '品种指标复审结果汇总'
                        }
                    },
                    {
                        path: 'expert-manage',
                        component: () => import(/* webpackChunkName: "reAudit-expert-manage" */ '@/pages/hxcm/reAudit-review/expert-manage'),
                        meta: {
                            title: '复审专家管理'
                        }
                    },
                    // score-count
                    {
                        path: 'reAudit-task-detailed',
                        component: () => import(/* webpackChunkName: "reAudit-task-detailed" */ '@/pages/hxcm/reAudit-review/reAudit-task-detailed'),
                        meta: {
                            title: '复审任务明细'
                        }
                    }
                ]
            },
            {
                path: 'publicity-management',
                component: View,
                meta: {
                    title: '公示信息管理'
                },
                children: [
                    {
                        path: 'list',
                        component: () => import(/* webpackChunkName: "publicity-management" */ '@/pages/hxcm/publicity-management/index'),
                        meta: {
                            title: '公示信息列表',
                            keepAlive: true
                        }
                    },
                    {
                        path: 'list/pro-list',
                        component: () => import(/* webpackChunkName: "reAudit-expert-manage" */ '@/pages/hxcm/publicity-management/pro-list'),
                        meta: {
                            title: '项目列表',
                            display: 'none'
                        }
                    }
                ]
            },
            // 申投诉-新
            {
                path: 'complaint-manage-new',
                component: View,
                meta: {
                    title: '申投诉管理1.2.0'
                },
                children: [
                    {
                        path: 'list',
                        component: () => import(/* webpackChunkName: "reAudit-expert-manage" */ '@/pages/hxcm/complaint-manage-new/index'),
                        meta: {
                            title: '申投诉信息1.2.0'
                        }
                    },
                    {
                        path: 'list/check',
                        component: () => import(/* webpackChunkName: "reAudit-expert-manage" */ '@/pages/hxcm/complaint-manage-new/check'),
                        meta: {
                            title: '申投诉信息处理1.2.0',
                            display: 'none'
                        }
                    },
                    // 咨询
                    {
                        path: 'list/check-zx',
                        component: () => import(/* webpackChunkName: "reAudit-expert-manage" */ '@/pages/hxcm/complaint-manage-new/check-zx'),
                        meta: {
                            title: '申投诉咨询信息1.2.0',
                            display: 'none'
                        }
                    }
                ]
            }
            // product-group

        ]
    }
];

export default routes;
